<template>
    <v-container fluid>
        <v-card class="mx-auto">
            <v-skeleton-loader v-if="loading" type="table-tbody"></v-skeleton-loader>
            <template v-else>
                <template v-if="registre != null">
                    <div v-for="categorie in categories" :key="categorie">
                        <h4>{{ categorie }}</h4>
                        <table class="table-registre">
                            <thead>
                                <tr class="header">
                                    <th>#/7jr</th>
                                    <th class="text-left">Médicament</th>
                                    <th class="text-left">Teneur</th>
                                    <th>Presc.</th>
                                    <td v-for="(h, horaireName) in abbrvHoraire" :key="horaireName">{{ h }}</td>
                                </tr>
                            </thead>
                            <tbody v-for="(item, index) in registre[categorie]" :key="item.nom + index">
                                <tr class="bold-line">
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'qte-duree-column ' + getClass(index, 'qte_duree')">{{
                                        item.qte_duree }}</td>
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'medicament-column ' + getClass(index, 'nom')">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"> {{ item.nom }} </span>
                                            </template>
                                            <span>Rx : {{ item.no_rx }}</span>
                                        </v-tooltip>
                                    </td>
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'teneur-column ' + getClass(index, 'teneur')">{{
                                        item.teneur }}</td>
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'prescripteur-column ' + getClass(index, 'prescripteur_nom_abbrege')">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"> {{ item.prescripteur_nom_abbrege }} </span>
                                            </template>
                                            <span>{{ item.prescripteur_nom_complet }}</span>
                                        </v-tooltip>
                                    </td>
                                    <td :class="'horaire-column ' + getClass(index, `posologie_json`)"
                                        v-for="(seq, horaireName) in item.posologie" :key="horaireName">{{ seq }}</td>
                                </tr>
                                <tr v-if="item.note_distribution != ''">
                                    <td colspan="4" :class="getClass(index, 'note_distribution')">{{
                                        item.note_distribution }}</td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <span v-if="item.posologie_type != 'quotidienne' && item.posologie_text != ''" :class="getClass(index, 'posologie_text')"> {{ item.posologie_text }} </span>
                                        <v-chip v-if="item.periode != ''" color="yellow" x-small> {{ item.periode }}</v-chip>
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                        <br />
                        <tbody v-if="show_diff==true">
                            <tr v-for="(item, index) in registre_changement?.diff" :key="item.nom + index" :set="changeClass=getChange(index)">
                                <td v-if="changeClass!=''">
                                    <v-chip
                                        :class="changeClass=='deleted' ? 'red' : (changeClass=='updated' ? 'orange' : 'green')"
                                        x-small> {{ changeClass=='deleted' ? 'SUPPRIMÉ' : (changeClass=='updated' ?
                                        'MODIFIÉ' : 'AJOUTÉ')}} </v-chip>
                                        <v-tooltip bottom v-if="changeClass=='updated'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"> {{ item.nom }} ({{ item.teneur }}) </span>
                                            </template>
                                            <span>{{ item.proprietes_changees?.join(' , ') }}</span>
                                        </v-tooltip>
                                        <span v-else>{{ item.nom }} ({{ item.teneur }})</span>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                </template>
                <v-alert v-else type="warning">La version {{ version }} est vide</v-alert>

                <v-card-actions>
                    <v-row no-gutters>
                        <v-dialog v-model="dialog" persistent max-width="400"
                            v-if="type == 'droite' && user.type == 'pharmacien' && user.licence != ''">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-1" small color="primary" dark v-bind="attrs" v-on="on" dense>
                                    Signer registre
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5">
                                    Confirmation
                                </v-card-title>
                                <v-card-text>Voulez-vous signer le registre de médicaments?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" @click="dialog = false">
                                        Non
                                    </v-btn>
                                    <v-btn color="success" v-on:click="signerRegistre()">
                                        Oui
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-btn v-if="this.type!='fullpage'"  color="primary" class="mx-1" small v-on:click="printRegistre" dense>
                            Imprimer
                        </v-btn>
                    </v-row>
                </v-card-actions>
            </template>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
const proprietes_changement_majeur = ['categorie', 'teneur', 'qte_duree', 'date_1er_jour_posologie_complexe', 'date_debut_traitement','date_fin_traitement', 'posologie_type', 'posologie_json','posologie_text']

export default {
    props: ["patient", "registre_changement", "type", "show_diff", "loading", "version"],
    data() {
        return {
            expanded: [],
            headers: [
                { text: 'Qte/Durée', value: 'qte_duree', sortable: false },
                { text: 'Nom', value: 'nom' },
                { text: 'Teneur', value: 'teneur' },
                { text: 'Debut', value: 'debut' },
                { text: 'Fin', value: 'fin' },
                { text: 'Prescripteur', value: 'prescripteur' },
                { text: '', value: 'data-table-expand' },
            ],
            abbrvHoraire: ['AM', 'DIN', 'SOU', 'HS'],
            horaires: ['MP1', 'MP2', 'MP3', 'MP4'],
            dialog: false,
            registre: [],
            categories: []
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    watch: {
        registre_changement: function () {
            this.categories = this.registre_changement!= null ? [...new Set(Object.values(this.registre_changement.formatted).map(obj => obj.categorie))] : [];
            this.registre = this.registre_changement != null ? Object.entries(this.registre_changement.formatted).reduce((acc, [key, obj]) => {
                const categorie = obj.categorie;
                if (!acc[categorie]) {
                    acc[categorie] = {};
                }
                acc[categorie][key] = obj;
                return acc;
            }, {}) : [];
        }
    },
    methods: {
        signerRegistre() {
            this.dialog = false;
            this.$emit("signer_registre");
        },
        printRegistre(){
            const paramsObj = { patient: this.patient, registre_changement: this.registre_changement, version: this.version,show_diff: this.show_diff };
            this.$router.push({ name: 'FeuilleRegistre', params: paramsObj });
        },
        getClass(id,property) {
            if(this.show_diff && this.registre_changement?.diff!=null && typeof this.registre_changement?.diff[id]!= 'undefined' && ( this.registre_changement?.diff[id].type_changement=='added' || this.registre_changement?.diff[id].type_changement=='deleted' || this.registre_changement?.diff[id].proprietes_changees.includes(property))){
                switch (this.registre_changement?.diff[id].type_changement) {
                    case "deleted": {
                        return 'deleted-item'
                    }
                    case "updated_major": {
                        return proprietes_changement_majeur.includes(property) ? 'updated-item' : ''
                    }
                    case "added": {
                        return 'added-item'
                    }
                    default: {
                        return ''
                    }
                }
            }
            return '';
        },
        getChange(id) {
            if(this.registre_changement?.diff!=null && typeof this.registre_changement?.diff[id]!= 'undefined' && this.registre_changement?.diff[id].type_changement!='identical' && this.registre_changement?.diff[id].type_changement!='updated_minor' ){
                switch (this.registre_changement?.diff[id].type_changement) {
                    case "deleted": {
                        return 'deleted'
                    }
                    case "updated_major": {
                        return 'updated';
                    }
                    case "added": {
                        return 'added';
                    }
                    default: {
                        return ''
                    }
                }
            }
            return '';
        },
    },
};
</script>

<style scoped>
.table-registre {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8em;
  tbody {
    border: 4px solid black;
  }

  td,
  th {
    text-align: center;
    height: 30px;
    border: 1px solid #1e1e1e;
  }
  p{
    margin-bottom: 0;
    height: 30px;
  }
}

tbody {
  tr {
    padding: 0;
  }
}
.deleted-item {
  background-color: #e62626c9;
  text-decoration: line-through;
}
.updated-item {
  background-color: orange;
}
.added-item {
  background-color: rgb(106, 255, 113);
}
.v-card {
    padding: 15px;
}

.qte-duree-column {
    width: 35px;
    text-align: right;
    padding: 0px 2px;
}

.medicament-column {
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0px 2px;
}

.teneur-column {
    width: 100px;
    text-align: left;
    padding: 0px 2px;
}

.prescripteur-column {
    width: 43px;
}

.horaire-column {
    width: 30px;
}

.bold-line {
    border-bottom: 1px solid black;
}

.date-line {
    text-align: center;
}
</style>