<template>
    <v-app>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-text>
                <p class="text-h4 text--primary">
                  {{ this.patient.prenom }} {{ this.patient.nom }}
                  <v-chip color="green" text-color="white">DDN : {{
                    this.patient.ddn | toDate("-")
                  }}</v-chip>
                  <v-chip color="green" text-color="white">Foyer : {{
                      this.foyer.nom_reflex
                    }}</v-chip>
                  <v-chip color="green" text-color="white">App : {{
                      this.patient.appt
                    }}</v-chip>
                </p>
                <h4>Version : {{ this.version }}</h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <registre
              type="fullpage"
              :patient_id="this.patient.id"
              :registre_changement="this.registre"
              :show_diff="this.show_diff"
              :version="this.version"
            />
        </v-row>
      </v-container>
    </v-app>
  
</template>

<script>
import Registre from "../components/Registre.vue";
import { mapGetters } from "vuex";

export default {
  props: ['patient', 'registre_changement','version', 'show_diff'],
  name: "FeuilleRegistre",
  data() {
    return {
      registre: null
    }
  },
  components: {
    registre: Registre,
  },
  computed: {
        foyer: function () {
        return this.db.foyer[this.patient.foyer_id]
            ? this.db.foyer[this.patient.foyer_id]
            : {
            id: 0,
            nom_reflex: "Aucun foyer",
            debut_cycle_facturation: 1,
            facturation_mensuelle: 0,
            };
        },
        ...mapGetters(['user','db']),
  },
  mounted() {
    this.registre = this.registre_changement
  }
};
</script>

<style lang="scss">
.green{
  background-color: #4CAF50 !important;
  border-color: #4CAF50 !important;
}
.orange{
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}
.red{
  background-color: #F44336 !important;
    border-color: #F44336 !important;
}
</style>